import { useState, useEffect, useRef } from 'preact/hooks';

import { ChordButton } from './ChordButton';
import { Breadcrumbs } from './Breadcrumbs';
import { DateAddedDivider } from './DateAddedDivider';
import { EachWithGroupDividers } from './EachWithGroupDividers';

export const ChordsList = ({ chords }) => {
  const [search, setSearch] = useState('');
  const inputRef = useRef();
  useEffect(() => inputRef.current.focus());
  function onInputChange({ target: { value } }) {
    setSearch(value);
  }
  return (
    <div>
      <Breadcrumbs />
      <ul class='component__chords-list'>
        <li class='component__chords-list__item'>
          <input class='component__chords-list__search' type='text' placeholder='filter' ref={inputRef} onKeyUp={onInputChange} value={search} />
        </li>
        <EachWithGroupDividers items={chords} groupBy={chordData => chordData.dateAdded} >{
          (chordData, group) => (
            <li class='component__chords-list__item' data-includes-divider={!!group}>
              { group && <DateAddedDivider>{group}</DateAddedDivider> }
              <ChordButton
                {...chordData}
                active={search && chordData.name.toLowerCase().indexOf(search.toLowerCase()) >= 0}
                />
            </li>
          )
        }</EachWithGroupDividers>
      </ul>
    </div>
  );
}
