import { useEffect, useState } from 'preact/hooks';
import Router from 'preact-router';
import { createHashHistory } from 'history';
import './style';
import { list, getOne } from './reducers';
import { ChordsList } from './components/ChordsList';
import { ChordSheet } from './components/ChordSheet';
import { Loading } from './components/Loading';

const OneChordRoute = ({ id, children }) => children(id);

const App = () => {
  const [content, setContent] = useState(false);
  useEffect(async () => {
    const content = await fetch(process.env.PREACT_APP_CONTENT_URL).then(x => x.json());
    setContent(content);
  }, []);

  if(!content) return <Loading />

  const chords = list(content);
  return (
    <div>
      <Router history={createHashHistory()}>
        <ChordsList path="/" chords={chords} />
        <OneChordRoute path="/:id">{(chordId) => (
          <ChordSheet chord={getOne(content, chordId)} />
        )}</OneChordRoute>
      </Router>
    </div>
  );
}

export default App;
