import { h, Fragment } from 'preact';

function assessGrouping(items, i, groupBy) {
  const item = items[i];
  const previous = items[i - 1];
  const previousGroupByValue = previous && groupBy(previous)
  const groupByValue = groupBy(item);
  const hasGroup = !!groupByValue;
  const isFirstItemInGroup = groupByValue !== previousGroupByValue;
  return { groupByValue, hasGroup, isFirstItemInGroup };
}

export const EachWithGroupDividers = ({ items, groupBy, children }) => (
  <Fragment>
    {items.map((item, i) => {
      const { groupByValue, hasGroup, isFirstItemInGroup } = assessGrouping(items, i, groupBy);
      return children(item, hasGroup && isFirstItemInGroup && groupByValue);
    })}
  </Fragment>
);
