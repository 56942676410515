import * as stable from 'stable'

function parseDateAdded(maybeDate) {
  const parsed = maybeDate && maybeDate.toString().match(/(\d\d\d\d-\d\d|\d\d\d\d)/)
  return parsed ? parsed[0] : '';
}
export function list(content) {
  const listData = Object.entries(content)
    .map(([id, data]) => ({ id, name: data.name, dateAdded: parseDateAdded(data.data.added) }));
  const sortByDateAddedAsc = (lhs, rhs) => lhs.dateAdded > rhs.dateAdded;
  return stable(listData, sortByDateAddedAsc);
}
