import { DocumentTitle } from './DocumentTitle';

export const Breadcrumbs = ({ current }) => (
  <>
    <DocumentTitle>{ current || 'Chord Book' }</DocumentTitle>
    <nav class='component__breadcrumbs'>
      <h3>
        <a href='/'>Chord Book</a>
        <span class='component__breadcrumbs__current'>{current}</span>
      </h3>
    </nav>
  </>
);
